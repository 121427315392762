import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../contact-form/contactform'; 
import './welcomemsg.css';

const Welcomemsg = ({ onBackButtonClick }) => {
  const { t } = useTranslation();

  return (
    <div className='after-submit-div'>
      <div className='after-submit'>
        <div className='after-submit-message'>
          <div className='welcome-msg-div'>
            <svg xmlns="http://www.w3.org/2000/svg" width="83" height="87" viewBox="0 0 83 87" fill="none">
              <path d="M41.5 4L51.8563 11.5548L64.677 11.5312L68.6141 23.7308L79 31.2462L75.0156 43.4301L79 55.614L68.6141 63.1294L64.677 75.3291L51.8563 75.3054L41.5 82.8602L31.1437 75.3054L18.323 75.3291L14.3859 63.1294L4 55.614L7.98441 43.4301L4 31.2462L14.3859 23.7308L18.323 11.5312L31.1437 11.5548L41.5 4Z" stroke="#0F3D3E" stroke-width="7.88602" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M27.7012 43.4298L37.5587 53.2873L57.2738 33.5723" stroke="#0F3D3E" stroke-width="7.88602" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className='welcome-title'>
            <h5>{t('contactform.welcome')}</h5>
          </div>
          <div className='welcome-title-msg'>
            <h5>{t('contactform.welcomemsg')}</h5>
          </div>
        </div>
        <div className='back-btn-wrapper'>
          <button  className='back-btn' onClick={onBackButtonClick}>
            <i className="fa-solid fa-arrow-left"></i> {t('contactform.backbtn')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcomemsg;
