// ContactPage.js
import React, { useState } from 'react';
import ContactForm from '../contact-form/contactform';
import Welcomemsg from '../Welcomemsg/welcome';

const ParentComponent = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
  
    const handleFormSubmit = () => {
      setFormSubmitted(true);
    };
  
    const handleBackButtonClick = () => {
      setFormSubmitted(false);
    };
  
    return (
      <div>
        {formSubmitted ? (
          <Welcomemsg onBackButtonClick={handleBackButtonClick} />
        ) : (
          <ContactForm onFormSubmit={handleFormSubmit} />
        )}
      </div>
    );
  };
  
  export default ParentComponent;
