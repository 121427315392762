import React, { useState } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderContent = (content) => {
    return (
      <ul className="accordion-content">
        {content.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    );
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const isActive = index === activeIndex;

      return (
        <div key={index} className={`accordion-item ${isActive ? 'active' : ''}`}>
          <div className="accordion-title" onClick={() => onTitleClick(index)}>
            <div className="accordion-title-first">
              <h3>{item.heading}</h3>
            </div>
            <div className="accordion-title-second">
              <span className="accordion-icon"><i class="fa-solid fa-angle-down"></i></span>
            </div>
          </div>
          {isActive && renderContent(item.content)}
        </div>
      );
    });
  };

  return <div className="accordion">{renderItems()}</div>;
};

export default Accordion;
