import React from 'react';

const Sociallinks = () => {
    return (
        <div className='association-social-links'>
            <ul className="social-icons">
                <li><span><a className="social-facebook" href="#" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></span></li>
                <li><span><a className="social-x" href="#" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></span></li>
                <li><span><a className="social-linkdin" href="#" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></span></li>
                <li><span><a className="social-instagram" href="#" target="_blank"><i className="fa-brands fa-instagram"></i></a></span></li>
                <li><span><a className="social-facebook" href="#" target="_blank"><i className="fa-brands fa-youtube"></i></a></span></li>

            </ul>
        </div>

    );
};
export default Sociallinks;
    