import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from "./components/header/header"
import Footer from "./components/footer/footer"
import Whatsappbtn from "./components/whatsappbtn/whatsappbtn"
import Chatbtn from "./components/chatbtn/chatbtn";
import './App.css';
import './custom.css'
import './responsive.css'
import HomePage from './app/home';
import TermsAndConditions from "./components/policies/termsandconditions";
import PrivacyPolicy from "./components/policies/privacypolicy";

function App() {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    if (window.location.hostname !== 'localhost') {
      document.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <div className="association-container">
      <BrowserRouter >
        <Header />
        <ScrollToTop />

        <Routes>
          <Route path="/" >
            <Route index element={<HomePage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
        <Footer />
        <Whatsappbtn />
        <Chatbtn />
      </BrowserRouter>

    </div>
  );
}

export default App;
