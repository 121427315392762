import React from "react";

import Tabs from "../Tabs/Tab";
import "./chatbtn.css";
import image1 from "./images/tab-pic-1.png";
import image2 from "./images/tab-pic-2.png";
import image3 from "./images/tab-pic-3.png";
import ContactForm from "../contact-form/contactform";
import Faqs from "../accordian/accordian";
import Background from "./images/backgroundhome.png";
import Welcomemsg from "../Welcomemsg/welcome";
import ContactPage from "../Contactpage/Contactpage";
import { useTranslation } from "react-i18next";

const Chatbtn = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const toggleChat = () => {
    const chatContainer = document.getElementById("chat-container");
    chatContainer.classList.toggle("active");
  };
  let faqItems;
  if (i18n.language === "english") {
    faqItems = [
      {
        heading: "1. Is there a trial period for the Premium Plan?",
        content: [
          "Yes, we offer a trial period for the Premium Plan for 90days, allowing users to experience features before committing.",
        ],
      },
      {
        heading:
          "2. Can I switch from the Free Plan to the Premium Plan at any time?",
        content: [
          "Yes, users have the flexibility to upgrade to the Premium Plan at any point.",
        ],
      },
      {
        heading: "3. Are there any limitations on the Free Plan?",
        content: ["There is no limitation; use it like a premium plan."],
      },
      {
        heading: "4. Can I cancel my subscription at any time?",
        content: ["No, you can't cancel existing subscription."],
      },

      {
        heading: "5. Do you store my credit card information?",
        content: [
          "No, we don't store your credit card information. Read our Privacy Policy to learn more..",
        ],
      },
      {
        heading: "6. Are there any hidden fees in the pricing models?",
        content: [
          "No, our pricing is transparent, and there are no hidden fees. Users pay only for the selected plan.",
        ],
      },
    ];
  } else if (i18n.language === "arabic") {
    faqItems = [
      {
        heading: "1.هل هناك فترة تجريبية للخطة المميزة؟",
        content: [
          "نعم، نحن نقدم فترة تجريبية للخطة المميزة لمدة 90 يومًا، مما يسمح للمستخدمين بتجربة الميزات قبل الالتزام.",
        ],
      },
      {
        heading:
          "2.هل يمكنني التبديل من الخطة المجانية إلى الخطة المميزة في أي وقت؟",
        content: [
          "نعم، يتمتع المستخدمون بالمرونة اللازمة للترقية إلى الخطة المميزة في أي وقت.",
        ],
      },
      {
        heading: "3. هل هناك أي قيود على الخطة المجانية؟",
        content: ["لا يوجد أي قيود. استخدمها كخطة متميزة."],
      },
      {
        heading: "4. هل يمكنني إلغاء اشتراكي في أي وقت؟",
        content: ["لا، لا يمكنك إلغاء الاشتراك الحالي."],
      },

      {
        heading: "5.هل تقومون بتخزين معلومات بطاقتي الائتمانية؟",
        content: [
          "لا، نحن لا نقوم بتخزين معلومات بطاقتك الائتمانية. اقرأ سياسة الخصوصية الخاصة بنا لمعرفة المزيد..",
        ],
      },
      {
        heading: "6. هل هناك أي رسوم مخفية في نماذج التسعير؟",
        content: [
          "لا، أسعارنا شفافة، ولا توجد رسوم مخفية. يدفع المستخدمون فقط مقابل الخطة المحددة.",
        ],
      },
    ];
  } else if (i18n.language === "bengali") {
    faqItems = [
      {
        heading: "1.প্রিমিয়াম প্ল্যানের জন্য কি কোনো ট্রায়াল পিরিয়ড আছে?",
        content: [
          "হ্যাঁ, আমরা 90 দিনের জন্য প্রিমিয়াম প্ল্যানের জন্য একটি ট্রায়াল পিরিয়ড অফার করি, যাতে ব্যবহারকারীরা প্রতিশ্রুতি দেওয়ার আগে বৈশিষ্ট্যগুলি অনুভব করতে পারে৷",
        ],
      },
      {
        heading:
          "2. আমি কি যেকোন সময় ফ্রি প্ল্যান থেকে প্রিমিয়াম প্ল্যানে স্যুইচ করতে পারি?",
        content: [
          "হ্যাঁ, ব্যবহারকারীদের যে কোনো সময়ে প্রিমিয়াম প্ল্যানে আপগ্রেড করার নমনীয়তা রয়েছে।",
        ],
      },
      {
        heading: "3. ফ্রি প্ল্যানের কোন সীমাবদ্ধতা আছে কি?",
        content: [
          "কোন সীমাবদ্ধতা নেই; এটি একটি প্রিমিয়াম পরিকল্পনা মত ব্যবহার করুন.",
        ],
      },
      {
        heading: "4. আমি কি যেকোনো সময় আমার সাবস্ক্রিপশন বাতিল করতে পারি?",
        content: ["না, আপনি বিদ্যমান সদস্যতা বাতিল করতে পারবেন না."],
      },

      {
        heading: "5.আপনি কি আমার ক্রেডিট কার্ডের তথ্য সংরক্ষণ করেন?",
        content: [
          "না, আমরা আপনার ক্রেডিট কার্ডের তথ্য সংরক্ষণ করি না। আরও জানতে আমাদের গোপনীয়তা নীতি পড়ুন..",
        ],
      },
      {
        heading: "6.মূল্যের মডেলগুলিতে কি কোন লুকানো ফি আছে?",
        content: [
          "না, আমাদের মূল্য স্বচ্ছ, এবং কোন লুকানো ফি নেই। ব্যবহারকারীরা শুধুমাত্র নির্বাচিত পরিকল্পনার জন্য অর্থ প্রদান করে।",
        ],
      },
    ];
  } else if (i18n.language === "dutch") {
    faqItems = [
      {
        heading: "1. Is er een proefperiode voor het Premium Plan?",
        content: [
          "Ja, we bieden een proefperiode van 90 dagen voor het Premium Plan, zodat gebruikers functies kunnen ervaren voordat ze zich binden.",
        ],
      },
      {
        heading:
          "2. Kan ik op elk moment overstappen van het gratis abonnement naar het premium abonnement?",
        content: [
          "Ja, gebruikers hebben de flexibiliteit om op elk moment te upgraden naar het Premium Plan.",
        ],
      },
      {
        heading: "3. Zijn er beperkingen op het gratis abonnement?",
        content: ["Er is geen beperking; gebruik het als een premiumplan."],
      },
      {
        heading: "4. Kan ik mijn abonnement op elk moment opzeggen?",
        content: ["Nee, u kunt een bestaand abonnement niet opzeggen."],
      },

      {
        heading: "5. Bewaart u mijn creditcardgegevens?",
        content: [
          "Nee, wij slaan uw creditcardgegevens niet op. Lees ons privacybeleid voor meer informatie..",
        ],
      },
      {
        heading: "6. Zijn er verborgen kosten in de prijsmodellen?",
        content: [
          "Nee, onze prijzen zijn transparant en er zijn geen verborgen kosten. Gebruikers betalen alleen voor het geselecteerde abonnement.",
        ],
      },
    ];
  } else if (i18n.language === "french") {
    faqItems = [
      {
        heading: "1.Y a-t-il une période d'essai pour le plan Premium?",
        content: [
          "Yes, we offer a trial period for the Premium Plan for 90days, allowing users to experience features before committing.",
        ],
      },
      {
        heading:
          "2. Puis-je passer du forfait gratuit au forfait premium à tout moment?",
        content: [
          "Oui, les utilisateurs ont la possibilité de passer au forfait Premium à tout moment.",
        ],
      },
      {
        heading: "3. Y a-t-il des limitations sur le forfait gratuit?",
        content: [
          "Il n'y a aucune limitation; utilisez-le comme un forfait premium.",
        ],
      },
      {
        heading: "4.Puis-je annuler mon abonnement à tout moment ?",
        content: ["Non, vous ne pouvez pas annuler un abonnement existant."],
      },

      {
        heading: "5.Stockez-vous les informations de ma carte de crédit?",
        content: [
          "Non, nous ne stockons pas les informations de votre carte de crédit. Lisez notre politique de confidentialité pour en savoir plus.",
        ],
      },
      {
        heading:
          "6. Y a-t-il des frais cachés dans les modèles de tarification?",
        content: [
          "Non, nos tarifs sont transparents et il n'y a pas de frais cachés. Les utilisateurs paient uniquement pour le forfait sélectionné.",
        ],
      },
    ];
  } else if (i18n.language === "german") {
    faqItems = [
      {
        heading: "1. Gibt es eine Testphase für den Premium-Plan?",
        content: [
          "Ja, wir bieten einen Testzeitraum für den Premium-Plan von 90 Tagen an, sodass Benutzer die Funktionen testen können, bevor sie sich verpflichten.",
        ],
      },
      {
        heading:
          "2. Kann ich jederzeit vom kostenlosen Plan zum Premium-Plan wechseln?",
        content: [
          "Ja, Benutzer haben jederzeit die Flexibilität, ein Upgrade auf den Premium-Plan durchzuführen",
        ],
      },
      {
        heading: "3.Gibt es Einschränkungen beim kostenlosen Plan?",
        content: [
          "Es gibt keine Einschränkung; Verwenden Sie es wie einen Premium-Plan.",
        ],
      },
      {
        heading: "4. Kann ich mein Abonnement jederzeit kündigen?",
        content: [
          "Nein, Sie können ein bestehendes Abonnement nicht kündigen.",
        ],
      },

      {
        heading: "5. Speichern Sie meine Kreditkarteninformationen?",
        content: [
          "No, we don't store your credit card information. Read our Privacy Policy to learn more..",
        ],
      },
      {
        heading: "6. Gibt es versteckte Gebühren in den Preismodellen?",
        content: [
          "Nein, unsere Preise sind transparent und es gibt keine versteckten Gebühren. Benutzer zahlen nur für den ausgewählten Plan.",
        ],
      },
    ];
  } else if (i18n.language === "gujarati") {
    faqItems = [
      {
        heading: "1.શું પ્રીમિયમ પ્લાન માટે કોઈ અજમાયશ અવધિ છે?",
        content: [
          "હા, અમે 90 દિવસ માટે પ્રીમિયમ પ્લાન માટે અજમાયશ અવધિ ઑફર કરીએ છીએ, જે વપરાશકર્તાઓને પ્રતિબદ્ધતા પહેલાં સુવિધાઓનો અનુભવ કરવાની મંજૂરી આપે છે.",
        ],
      },
      {
        heading:
          "2.શું હું કોઈપણ સમયે ફ્રી પ્લાનમાંથી પ્રીમિયમ પ્લાન પર સ્વિચ કરી શકું?",
        content: [
          "હા, વપરાશકર્તાઓ પાસે કોઈપણ સમયે પ્રીમિયમ પ્લાનમાં અપગ્રેડ કરવાની સુગમતા છે.",
        ],
      },
      {
        heading: "3. શું ફ્રી પ્લાન પર કોઈ મર્યાદાઓ છે?",
        content: [
          "ત્યાં કોઈ મર્યાદા નથી; તેનો ઉપયોગ પ્રીમિયમ પ્લાનની જેમ કરો.",
        ],
      },
      {
        heading: "4. શું હું કોઈપણ સમયે મારું સબ્સ્ક્રિપ્શન રદ કરી શકું?",
        content: ["ના, તમે હાલનું સબ્સ્ક્રિપ્શન રદ કરી શકતા નથી"],
      },

      {
        heading: "5. શું તમે મારી ક્રેડિટ કાર્ડ માહિતી સંગ્રહિત કરો છો?",
        content: [
          "ના, અમે તમારી ક્રેડિટ કાર્ડ માહિતી સંગ્રહિત કરતા નથી. વધુ જાણવા માટે અમારી ગોપનીયતા નીતિ વાંચો..",
        ],
      },
      {
        heading: "6. શું કિંમતના મોડલ્સમાં કોઈ છુપાયેલ ફી છે?",
        content: [
          "ના, અમારી કિંમતો પારદર્શક છે અને તેમાં કોઈ છુપી ફી નથી. વપરાશકર્તાઓ ફક્ત પસંદ કરેલા પ્લાન માટે જ ચૂકવણી કરે છે.",
        ],
      },
    ];
  } else if (i18n.language === "hindi") {
    faqItems = [
      {
        heading: "1. क्या प्रीमियम योजना के लिए कोई परीक्षण अवधि है?",
        content: [
          "हां, हम प्रीमियम योजना के लिए 90 दिनों की परीक्षण अवधि प्रदान करते हैं, जिससे उपयोगकर्ता प्रतिबद्ध होने से पहले सुविधाओं का अनुभव कर सकते हैं।",
        ],
      },
      {
        heading:
          "2.क्या मैं किसी भी समय निःशुल्क योजना से प्रीमियम योजना पर स्विच कर सकता हूँ?",
        content: [
          "हां, उपयोगकर्ताओं के पास किसी भी समय प्रीमियम योजना में अपग्रेड करने की सुविधा है।",
        ],
      },
      {
        heading: "3. क्या निःशुल्क योजना पर कोई सीमाएँ हैं?",
        content: ["कोई सीमा नहीं है; इसे एक प्रीमियम योजना की तरह उपयोग करें।"],
      },
      {
        heading: "4.क्या मैं किसी भी समय अपनी सदस्यता रद्द कर सकता हूँ?",
        content: ["नहीं, आप मौजूदा सदस्यता रद्द नहीं कर सकते."],
      },

      {
        heading: "5. क्या आप मेरे क्रेडिट कार्ड की जानकारी संग्रहीत करते हैं?",
        content: [
          "नहीं, हम आपके क्रेडिट कार्ड की जानकारी संग्रहीत नहीं करते हैं। अधिक जानने के लिए हमारी गोपनीयता नीति पढ़ें..",
        ],
      },
      {
        heading: "6.क्या मूल्य निर्धारण मॉडल में कोई छिपी हुई फीस है?",
        content: [
          "नहीं, हमारा मूल्य निर्धारण पारदर्शी है, और कोई छिपी हुई फीस नहीं है। उपयोगकर्ता केवल चयनित योजना के लिए भुगतान करते हैं.",
        ],
      },
    ];
  } else if (i18n.language === "italian") {
    faqItems = [
      {
        heading: "1. È previsto un periodo di prova per il piano Premium?",
        content: [
          "Sì, offriamo un periodo di prova per il piano Premium per 90 giorni, consentendo agli utenti di provare le funzionalità prima di impegnarsi.",
        ],
      },
      {
        heading:
          "2. Posso passare dal piano gratuito al piano premium in qualsiasi momento?",
        content: [
          "Sì, gli utenti hanno la flessibilità di passare al piano Premium in qualsiasi momento.",
        ],
      },
      {
        heading: "3. Ci sono limitazioni sul piano gratuito?",
        content: ["Non ci sono limitazioni; usalo come un piano premium."],
      },
      {
        heading: "4.Posso annullare il mio abbonamento in qualsiasi momento?",
        content: ["No, non puoi annullare l'abbonamento esistente."],
      },

      {
        heading: "5.Memorizzi i dati della mia carta di credito?",
        content: [
          "No, non memorizziamo i dati della tua carta di credito. Leggi la nostra Privacy Policy per saperne di più..",
        ],
      },
      {
        heading: "6. Ci sono costi nascosti nei modelli di prezzo?",
        content: [
          "No, i nostri prezzi sono trasparenti e non ci sono costi nascosti. Gli utenti pagano solo per il piano selezionato",
        ],
      },
    ];
  } else if (i18n.language === "japanese") {
    faqItems = [
      {
        heading: "1. プレミアムプランにお試し期間はありますか?",
        content: [
          "はい、プレミアム プランには 90 日間の試用期間があり、ユーザーは契約する前に機能を体験できます。",
        ],
      },
      {
        heading:
          "2.いつでも無料プランからプレミアムプランに切り替えることができますか?",
        content: [
          "はい、ユーザーはいつでもプレミアム プランに柔軟にアップグレードできます。.",
        ],
      },
      {
        heading: "3. 無料プランには制限はありますか?",
        content: ["制限はありません。プレミアムプランのように使えます。"],
      },
      {
        heading: "4. サブスクリプションはいつでもキャンセルできますか?",
        content: [
          "いいえ、既存のサブスクリプションをキャンセルすることはできません。",
        ],
      },

      {
        heading: "5.私のクレジットカード情報は保管されますか?",
        content: [
          "いいえ、クレジット カード情報は保存されません。詳細については、当社のプライバシー ポリシーをお読みください。",
        ],
      },
      {
        heading: "6. 価格モデルに隠れた料金はありますか?",
        content: [
          "いいえ、価格設定は透明性があり、隠れた手数料はありません。ユーザーは選択したプランの料金のみを支払います.",
        ],
      },
    ];
  } else if (i18n.language === "kannada") {
    faqItems = [
      {
        heading: "1. ಪ್ರೀಮಿಯಂ ಯೋಜನೆಗೆ ಪ್ರಾಯೋಗಿಕ ಅವಧಿ ಇದೆಯೇ?",
        content: [
          "ಹೌದು, ನಾವು 90 ದಿನಗಳವರೆಗೆ ಪ್ರೀಮಿಯಂ ಯೋಜನೆಗಾಗಿ ಪ್ರಾಯೋಗಿಕ ಅವಧಿಯನ್ನು ನೀಡುತ್ತೇವೆ, ಬಳಕೆದಾರರಿಗೆ ಬದ್ಧರಾಗುವ ಮೊದಲು ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಅನುಭವಿಸಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ.",
        ],
      },
      {
        heading:
          "2. ನಾನು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಉಚಿತ ಯೋಜನೆಯಿಂದ ಪ್ರೀಮಿಯಂ ಯೋಜನೆಗೆ ಬದಲಾಯಿಸಬಹುದೇ?",
        content: [
          "ಹೌದು, ಬಳಕೆದಾರರು ಯಾವುದೇ ಹಂತದಲ್ಲಿ ಪ್ರೀಮಿಯಂ ಯೋಜನೆಗೆ ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಲು ನಮ್ಯತೆಯನ್ನು ಹೊಂದಿರುತ್ತಾರೆ.",
        ],
      },
      {
        heading: "3.ಉಚಿತ ಯೋಜನೆಯಲ್ಲಿ ಯಾವುದೇ ಮಿತಿಗಳಿವೆಯೇ?",
        content: ["ಯಾವುದೇ ಮಿತಿಯಿಲ್ಲ; ಇದನ್ನು ಪ್ರೀಮಿಯಂ ಯೋಜನೆಯಂತೆ ಬಳಸಿ."],
      },
      {
        heading:
          "4. ನಾನು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನನ್ನ ಚಂದಾದಾರಿಕೆಯನ್ನು ರದ್ದುಗೊಳಿಸಬಹುದೇ?",
        content: [
          "ಇಲ್ಲ, ನೀವು ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಚಂದಾದಾರಿಕೆಯನ್ನು ರದ್ದುಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
        ],
      },

      {
        heading: "5.ನೀವು ನನ್ನ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುತ್ತೀರಾ?",
        content: [
          "ಇಲ್ಲ, ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮಾಹಿತಿಯನ್ನು ನಾವು ಸಂಗ್ರಹಿಸುವುದಿಲ್ಲ. ಇನ್ನಷ್ಟು ತಿಳಿಯಲು ನಮ್ಮ ಗೌಪ್ಯತಾ ನೀತಿಯನ್ನು ಓದಿ..",
        ],
      },
      {
        heading: "6. ಬೆಲೆ ಮಾದರಿಗಳಲ್ಲಿ ಯಾವುದೇ ಗುಪ್ತ ಶುಲ್ಕಗಳಿವೆಯೇ?",
        content: [
          "ಇಲ್ಲ, ನಮ್ಮ ಬೆಲೆ ಪಾರದರ್ಶಕವಾಗಿದೆ ಮತ್ತು ಯಾವುದೇ ಗುಪ್ತ ಶುಲ್ಕಗಳಿಲ್ಲ. ಆಯ್ದ ಯೋಜನೆಗೆ ಮಾತ್ರ ಬಳಕೆದಾರರು ಪಾವತಿಸುತ್ತಾರೆ",
        ],
      },
    ];
  } else if (i18n.language === "korean") {
    faqItems = [
      {
        heading: "1. 프리미엄 플랜에 체험 기간이 있나요?",
        content: [
          "예, 우리는 프리미엄 플랜에 대한 90일 평가판 기간을 제공하여 사용자가 약정하기 전에 기능을 경험할 수 있도록 합니다.",
        ],
      },
      {
        heading:
          "2. 언제든지 무료 플랜에서 프리미엄 플랜으로 전환할 수 있나요?",
        content: [
          "예, 사용자는 언제든지 프리미엄 플랜으로 업그레이드할 수 있습니다.",
        ],
      },
      {
        heading: "3. 무료 플랜에 제한이 있나요?",
        content: ["제한은 없습니다. 프리미엄 요금제처럼 사용하세요."],
      },
      {
        heading: "4. 언제든지 구독을 취소할 수 있나요?",
        content: ["아니요, 기존 구독을 취소할 수 없습니다."],
      },

      {
        heading: "5. 내 신용카드 정보를 저장하나요?",
        content: [
          "아니요, 저희는 귀하의 신용카드 정보를 저장하지 않습니다. 자세한 내용은 개인정보 보호정책을 읽어보세요.",
        ],
      },
      {
        heading: "6.가격 모델에 숨겨진 수수료가 있나요?",
        content: [
          "아니요. 가격은 투명하며 숨겨진 수수료가 없습니다. 사용자는 선택한 요금제에 대해서만 비용을 지불합니다.",
        ],
      },
    ];
  } 
  else if (i18n.language === "malayalam") {
    faqItems = [
      {
        heading: "1.പ്രീമിയം പ്ലാനിന് ട്രയൽ പിരീഡ് ഉണ്ടോ?",
        content: [
        "അതെ, പ്രീമിയം പ്ലാനിനായി 90 ദിവസത്തേക്കുള്ള ഒരു ട്രയൽ കാലയളവ് ഞങ്ങൾ വാഗ്ദാനം ചെയ്യുന്നു, കമ്മിറ്റ് ചെയ്യുന്നതിന് മുമ്പ് ഫീച്ചറുകൾ അനുഭവിക്കാൻ ഉപയോക്താക്കളെ അനുവദിക്കുന്നു.",
        ],
      },
      {
        heading:
          "2.എനിക്ക് എപ്പോൾ വേണമെങ്കിലും സൗജന്യ പ്ലാനിൽ നിന്ന് പ്രീമിയം പ്ലാനിലേക്ക് മാറാൻ കഴിയുമോ?",
        content: [
          "അതെ, ഉപയോക്താക്കൾക്ക് ഏത് സമയത്തും പ്രീമിയം പ്ലാനിലേക്ക് അപ്‌ഗ്രേഡ് ചെയ്യാനുള്ള സൗകര്യമുണ്ട്.",
        ],
      },
      {
        heading: "3.സൗജന്യ പദ്ധതിയിൽ എന്തെങ്കിലും പരിമിതികൾ ഉണ്ടോ?",
        content: ["പരിമിതികളില്ല; ഇത് ഒരു പ്രീമിയം പ്ലാൻ പോലെ ഉപയോഗിക്കുക."],
      },
      {
        heading: "4.എനിക്ക് എപ്പോൾ വേണമെങ്കിലും എൻ്റെ സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കാനാകുമോ?",
        content: ["ഇല്ല, നിങ്ങൾക്ക് നിലവിലുള്ള സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കാനാകില്ല."],
      },

      {
        heading: "5.നിങ്ങൾ എൻ്റെ ക്രെഡിറ്റ് കാർഡ് വിവരങ്ങൾ സംഭരിക്കുന്നുണ്ടോ?",
        content: [
          "ഇല്ല, ഞങ്ങൾ നിങ്ങളുടെ ക്രെഡിറ്റ് കാർഡ് വിവരങ്ങൾ സംഭരിക്കുന്നില്ല. കൂടുതലറിയാൻ ഞങ്ങളുടെ സ്വകാര്യതാ നയം വായിക്കുക...",
        ],
      },
      {
        heading: "6.വിലനിർണ്ണയ മോഡലുകളിൽ എന്തെങ്കിലും മറഞ്ഞിരിക്കുന്ന ഫീസ് ഉണ്ടോ?",
        content: [
          "ഇല്ല, ഞങ്ങളുടെ വിലനിർണ്ണയം സുതാര്യമാണ്, മറഞ്ഞിരിക്കുന്ന ഫീസുകളൊന്നുമില്ല. തിരഞ്ഞെടുത്ത പ്ലാനിന് മാത്രമേ ഉപയോക്താക്കൾ പണം നൽകൂ.",
        ],
      },
    ];
  }
  else if (i18n.language === "marathi") {
    faqItems = [
      {
        heading: "1. प्रीमियम प्लॅनसाठी चाचणी कालावधी आहे का?",
        content: [
          "होय, आम्ही प्रीमियम प्लॅनसाठी 90 दिवसांसाठी चाचणी कालावधी ऑफर करतो, ज्यामुळे वापरकर्त्यांना कमिट करण्यापूर्वी वैशिष्ट्यांचा अनुभव घेता येतो.",
        ],
      },
      {
        heading:
          "2.मी फ्री प्लॅनमधून प्रीमियम प्लॅनवर कधीही स्विच करू शकतो का?",
        content: [
          "होय, वापरकर्त्यांना प्रीमियम प्लॅनमध्ये कोणत्याही वेळी अपग्रेड करण्याची लवचिकता आहे.",
        ],
      },
      {
        heading: "3. मोफत योजनेवर काही मर्यादा आहेत का?",
        content: ["कोणतीही मर्यादा नाही; प्रीमियम योजनेप्रमाणे वापरा."],
      },
      {
        heading: "4.मी माझी सदस्यता कधीही रद्द करू शकतो का?",
        content: ["नाही, तुम्ही विद्यमान सदस्यत्व रद्द करू शकत नाही."],
      },

      {
        heading: "5.तुम्ही माझी क्रेडिट कार्ड माहिती साठवता का?",
        content: [
          "नाही, आम्ही तुमची क्रेडिट कार्ड माहिती साठवत नाही. अधिक जाणून घेण्यासाठी आमचे गोपनीयता धोरण वाचा..",
        ],
      },
      {
        heading: "6.किंमतींच्या मॉडेलमध्ये काही छुपे शुल्क आहेत का?",
        content: [
          "नाही, आमची किंमत पारदर्शक आहे आणि कोणतेही छुपे शुल्क नाहीत. वापरकर्ते फक्त निवडलेल्या योजनेसाठी पैसे देतात.",
        ],
      },
    ];
  } else if (i18n.language === "portuguese") {
    faqItems = [
      {
        heading: "1. Existe um período de teste para o Plano Premium?",
        content: [
          "Sim, oferecemos um período de teste do Plano Premium por 90 dias, permitindo que os usuários experimentem os recursos antes de se comprometerem.",
        ],
      },
      {
        heading:
          "2. Posso mudar do Plano Gratuito para o Plano Premium a qualquer momento?",
        content: [
          "Sim, os usuários têm a flexibilidade de atualizar para o Plano Premium a qualquer momento.",
        ],
      },
      {
        heading: "3.Há alguma limitação no Plano Gratuito?",
        content: ["Não há limitação; use-o como um plano premium."],
      },
      {
        heading: "4. Posso cancelar minha assinatura a qualquer momento?",
        content: ["Não, você não pode cancelar a assinatura existente."],
      },

      {
        heading: "5.Você armazena as informações do meu cartão de crédito?",
        content: [
          "Não, não armazenamos as informações do seu cartão de crédito. Leia nossa Política de Privacidade para saber mais..",
        ],
      },
      {
        heading: "6. Existem taxas ocultas nos modelos de preços?",
        content: [
          "Não, nossos preços são transparentes e não há taxas ocultas. Os usuários pagam apenas pelo plano selecionado.",
        ],
      },
    ];
  } else if (i18n.language === "russian") {
    faqItems = [
      {
        heading: "1. Есть ли пробный период для Премиум-плана?",
        content: [
          "Да, мы предлагаем пробный период для Премиум-плана на 90 дней, что позволяет пользователям опробовать функции перед принятием решения.",
        ],
      },
      {
        heading:
          "2.Могу ли я в любое время перейти с бесплатного плана на премиум-план?",
        content: [
          "Да, пользователи имеют возможность перейти на Премиум-план в любой момент.",
        ],
      },
      {
        heading: "3.Есть ли какие-либо ограничения на бесплатном плане?",
        content: ["Нет никаких ограничений; используйте его как премиум-план."],
      },
      {
        heading: "4. Могу ли я отменить подписку в любое время?",
        content: ["Нет, вы не можете отменить существующую подписку."],
      },

      {
        heading: "5.Вы храните данные моей кредитной карты?",
        content: [
          "Нет, мы не храним данные вашей кредитной карты. Прочтите нашу Политику конфиденциальности, чтобы узнать больше..",
        ],
      },
      {
        heading:
          "6. Есть ли какие-либо скрытые платежи в моделях ценообразования?",
        content: [
          "Нет, наши цены прозрачны и не имеют скрытых комиссий. Пользователи платят только за выбранный план.",
        ],
      },
    ];
  } else if (i18n.language === "spanish") {
    faqItems = [
      {
        heading: "1. ¿Existe un período de prueba para el Plan Premium?",
        content: [
          "Sí, ofrecemos un período de prueba para el Plan Premium durante 90 días, lo que permite a los usuarios experimentar funciones antes de comprometerse.",
        ],
      },
      {
        heading:
          "2. ¿Puedo cambiar del Plan Gratuito al Plan Premium en cualquier momento?",
        content: [
          "Sí, los usuarios tienen la flexibilidad de actualizar al Plan Premium en cualquier momento.",
        ],
      },
      {
        heading: "3.¿Existe alguna limitación en el Plan Gratuito?",
        content: ["No hay limitación; Úselo como un plan premium."],
      },
      {
        heading: "4.¿Puedo cancelar mi suscripción en cualquier momento?",
        content: ["No, no puedes cancelar la suscripción existente."],
      },

      {
        heading: "5.¿Almacenan la información de mi tarjeta de crédito?",
        content: [
          "No, no almacenamos la información de su tarjeta de crédito. Lea nuestra Política de Privacidad para obtener más información..",
        ],
      },
      {
        heading: "6. ¿Hay tarifas ocultas en los modelos de precios?",
        content: [
          "No, nuestros precios son transparentes y no hay tarifas ocultas. Los usuarios pagan solo por el plan seleccionado..",
        ],
      },
    ];
  } else if (i18n.language === "swedish") {
    faqItems = [
      {
        heading: "1. Finns det en provperiod för premiumplanen?",
        content: [
          "Ja, vi erbjuder en provperiod för Premium-planen i 90 dagar, vilket gör att användarna kan uppleva funktioner innan de förbinder sig",
        ],
      },
      {
        heading:
          "2. Kan jag byta från gratisplanen till premiumplanen när som helst?",
        content: [
          "Ja, användare har flexibiliteten att uppgradera till premiumplanen när som helst.",
        ],
      },
      {
        heading: "3. Finns det några begränsningar för gratisplanen?",
        content: [
          "Det finns ingen begränsning; använd det som en premiumplan.",
        ],
      },
      {
        heading: "4.Kan jag säga upp mitt abonnemang när som helst?",
        content: ["Nej, du kan inte säga upp ett befintligt abonnemang."],
      },

      {
        heading: "5.Sparar du min kreditkortsinformation?",
        content: [
          "Nej, vi lagrar inte din kreditkortsinformation. Läs vår sekretesspolicy för att lära dig mer..",
        ],
      },
      {
        heading: "6. Finns det några dolda avgifter i prismodellerna?",
        content: [
          "Nej, vår prissättning är transparent och det finns inga dolda avgifter. Användare betalar endast för den valda planen.",
        ],
      },
    ];
  } else if (i18n.language === "tamil") {
    faqItems = [
      {
        heading: "1.பிரீமியம் திட்டத்திற்கு சோதனை காலம் உள்ளதா?",
        content: [
          "ஆம், பிரீமியம் திட்டத்திற்கான சோதனைக் காலத்தை 90 நாட்களுக்கு வழங்குகிறோம், இதன் மூலம் பயனர்கள் அம்சங்களை அனுபவிக்க முடியும்",
        ],
      },
      {
        heading:
          "2.நான் எப்போது வேண்டுமானாலும் இலவச திட்டத்தில் இருந்து பிரீமியம் திட்டத்திற்கு மாற முடியுமா?",
        content: [
          "ஆம், எந்த நேரத்திலும் பிரீமியம் திட்டத்திற்கு மேம்படுத்த பயனர்களுக்கு நெகிழ்வுத்தன்மை உள்ளது.",
        ],
      },
      {
        heading: "3. இலவச திட்டத்தில் ஏதேனும் வரம்புகள் உள்ளதா?",
        content: ["வரம்பு இல்லை; பிரீமியம் திட்டம் போல் பயன்படுத்தவும்."],
      },
      {
        heading: "4.எந்த நேரத்திலும் எனது சந்தாவை ரத்து செய்ய முடியுமா?",
        content: ["இல்லை, நீங்கள் ஏற்கனவே உள்ள சந்தாவை ரத்து செய்ய முடியாது."],
      },

      {
        heading: "5. எனது கிரெடிட் கார்டு தகவலைச் சேமிக்கிறீர்களா?",
        content: [
          "இல்லை, உங்கள் கிரெடிட் கார்டு தகவலை நாங்கள் சேமிப்பதில்லை. மேலும் அறிய எங்கள் தனியுரிமைக் கொள்கையைப் படிக்கவும்..",
        ],
      },
      {
        heading: "6. விலை மாடல்களில் ஏதேனும் மறைக்கப்பட்ட கட்டணங்கள் உள்ளதா?",
        content: [
          "இல்லை, எங்கள் விலை வெளிப்படையானது மற்றும் மறைக்கப்பட்ட கட்டணங்கள் எதுவும் இல்லை. தேர்ந்தெடுக்கப்பட்ட திட்டத்திற்கு மட்டுமே பயனர்கள் பணம் செலுத்துகிறார்கள்.",
        ],
      },
    ];
  } else if (i18n.language === "telugu") {
    faqItems = [
      {
        heading: "1.ప్రీమియం ప్లాన్ కోసం ట్రయల్ పీరియడ్ ఉందా?",
        content: [
          "అవును, మేము ప్రీమియం ప్లాన్ కోసం 90 రోజుల పాటు ట్రయల్ పీరియడ్‌ని అందిస్తాము, దీని ద్వారా వినియోగదారులు కమిట్ అయ్యే ముందు ఫీచర్‌లను అనుభవించవచ్చు",
        ],
      },
      {
        heading:
          "2.నేను ఎప్పుడైనా ఉచిత ప్లాన్ నుండి ప్రీమియం ప్లాన్‌కి మారవచ్చా?",
        content: [
          "అవును, వినియోగదారులు ఎప్పుడైనా ప్రీమియం ప్లాన్‌కి అప్‌గ్రేడ్ చేసుకునే సౌలభ్యాన్ని కలిగి ఉంటారు.",
        ],
      },
      {
        heading: "3.ఉచిత ప్లాన్‌పై ఏమైనా పరిమితులు ఉన్నాయా?",
        content: ["పరిమితి లేదు; దీన్ని ప్రీమియం ప్లాన్ లాగా ఉపయోగించండి."],
      },
      {
        heading: "4. నేను ఎప్పుడైనా నా సభ్యత్వాన్ని రద్దు చేయవచ్చా?",
        content: ["లేదు, మీరు ఇప్పటికే ఉన్న సభ్యత్వాన్ని రద్దు చేయలేరు."],
      },

      {
        heading: "5. మీరు నా క్రెడిట్ కార్డ్ సమాచారాన్ని నిల్వ చేస్తున్నారా?",
        content: [
          "లేదు, మేము మీ క్రెడిట్ కార్డ్ సమాచారాన్ని నిల్వ చేయము. మరింత తెలుసుకోవడానికి మా గోప్యతా విధానాన్ని చదవండి..",
        ],
      },
      {
        heading: "6.ప్రైసింగ్ మోడల్‌లలో ఏదైనా దాచిన ఫీజులు ఉన్నాయా?",
        content: [
          "లేదు, మా ధర పారదర్శకంగా ఉంటుంది మరియు దాచిన రుసుములు లేవు. ఎంచుకున్న ప్లాన్‌కు మాత్రమే వినియోగదారులు చెల్లిస్తారు.",
        ],
      },
    ];
  } else if (i18n.language === "turkish") {
    faqItems = [
      {
        heading: "1.Premium Planın deneme süresi var mı?",
        content: [
          "Evet, Premium Plan için 90 günlük bir deneme süresi sunuyoruz, böylece kullanıcıların taahhütte bulunmadan önce özellikleri deneyimlemelerine olanak sağlıyoruz.",
        ],
      },
      {
        heading:
          "2.Ücretsiz Plandan Premium Plana istediğim zaman geçiş yapabilir miyim?",
        content: [
          "Evet, kullanıcılar istedikleri zaman Premium Plana yükseltme esnekliğine sahiptir.",
        ],
      },
      {
        heading: "3. Ücretsiz Planda herhangi bir sınırlama var mı?",
        content: ["Hiçbir sınırlama yoktur; premium plan gibi kullanın."],
      },
      {
        heading: "4. Aboneliğimi istediğim zaman iptal edebilir miyim?",
        content: ["Hayır, mevcut aboneliğinizi iptal edemezsiniz."],
      },

      {
        heading: "5. Kredi kartı bilgilerimi saklıyor musunuz?",
        content: [
          "Hayır, kredi kartı bilgilerinizi saklamıyoruz. Daha fazlasını öğrenmek için Gizlilik Politikamızı okuyun..",
        ],
      },
      {
        heading: "6. Fiyatlandırma modellerinde gizli ücretler var mı?",
        content: [
          "Hayır, fiyatlarımız şeffaftır ve hiçbir gizli ücret yoktur. Kullanıcılar yalnızca seçilen plan için ödeme yapar.",
        ],
      },
    ];
  } else {
    faqItems = [
      {
        heading: "1. Is there a trial period for the Premium Plan?",
        content: [
          "Yes, we offer a trial period for the Premium Plan for 90days, allowing users to experience features before committing.",
        ],
      },
      {
        heading:
          "2. Can I switch from the Free Plan to the Premium Plan at any time?",
        content: [
          "Yes, users have the flexibility to upgrade to the Premium Plan at any point.",
        ],
      },
      {
        heading: "3. Are there any limitations on the Free Plan?",
        content: ["There is no limitation; use it like a premium plan."],
      },
      {
        heading: "4. Can I cancel my subscription at any time?",
        content: ["No, you can't cancel existing subscription."],
      },

      {
        heading: "5. Do you store my credit card information?",
        content: [
          "No, we don't store your credit card information. Read our Privacy Policy to learn more..",
        ],
      },
      {
        heading: "6. Are there any hidden fees in the pricing models?",
        content: [
          "No, our pricing is transparent, and there are no hidden fees. Users pay only for the selected plan.",
        ],
      },
    ];
  }
  return (
    <div className="chatbtn-container">
      <a
        id="chat-btn-triger"
        onClick={toggleChat}
        className="chatbtn"
        href="javascript:void(0);"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="98"
          height="98"
          viewBox="0 0 98 98"
          fill="none"
        >
          <circle
            cx="48.9065"
            cy="48.9065"
            r="48.9065"
            fill="url(#paint0_linear_3341_535)"
            fill-opacity="0.1"
          />
          <circle cx="48.9074" cy="48.9074" r="38.5324" fill="#678983" />
          <rect
            x="25.7871"
            y="28.8711"
            width="47.7801"
            height="38.5324"
            fill="white"
          />
          <path
            d="M48.9054 10.373C27.6248 10.373 10.373 27.6248 10.373 48.9054C10.373 70.1861 27.6248 87.4378 48.9054 87.4378C70.1861 87.4378 87.4378 70.1861 87.4378 48.9054C87.4378 27.6248 70.1861 10.373 48.9054 10.373ZM60.1122 60.1183C56.9726 61.9217 53.1317 62.9821 48.9871 62.9821C38.3522 62.9821 29.7333 56.0031 29.7333 47.398C29.7333 38.7914 38.3537 31.8155 48.9871 31.8155C59.619 31.8155 68.2379 38.7914 68.2379 47.398C68.2379 49.6792 67.6322 51.8462 66.5425 53.799L69.9595 63.4213L60.1122 60.1183Z"
            fill="#F1675E"
          />
          <path
            d="M41.2327 49.6374C42.5683 49.6374 43.651 48.5547 43.651 47.2191C43.651 45.8835 42.5683 44.8008 41.2327 44.8008C39.8972 44.8008 38.8145 45.8835 38.8145 47.2191C38.8145 48.5547 39.8972 49.6374 41.2327 49.6374Z"
            fill="#F1675E"
          />
          <path
            d="M48.7347 49.694C50.0703 49.694 51.153 48.6113 51.153 47.2757C51.153 45.9401 50.0703 44.8574 48.7347 44.8574C47.3991 44.8574 46.3164 45.9401 46.3164 47.2757C46.3164 48.6113 47.3991 49.694 48.7347 49.694Z"
            fill="#F1675E"
          />
          <path
            d="M56.4007 49.4733C57.7363 49.4733 58.819 48.3906 58.819 47.055C58.819 45.7194 57.7363 44.6367 56.4007 44.6367C55.0651 44.6367 53.9824 45.7194 53.9824 47.055C53.9824 48.3906 55.0651 49.4733 56.4007 49.4733Z"
            fill="#F1675E"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3341_535"
              x1="19.2419"
              y1="11.6253"
              x2="80.5754"
              y2="84.5841"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </a>

      <div id="chat-container" className="chat-container">
        <button className="cross-button" onClick={toggleChat}>
          <i class="fa-solid fa-xmark"></i>
        </button>

        <Tabs
          tabData={[
            {
              tabName: "Tab1",
              buttonContent: <div> <i className="fa-solid fa-house"></i></div>,
              content: (
                <div className="home-tab">
                  <div className="logo-part">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="82"
                      height="52"
                      viewBox="0 0 82 52"
                      fill="none"
                    >
                      <path
                        d="M76.4406 25.3654L81.9984 0L66.5566 25.3445C69.8618 25.1452 73.1565 25.1452 76.4406 25.3654Z"
                        fill="#678983"
                      />
                      <path
                        d="M66.5586 25.3444L82.0003 0L12.0547 44.7014C21.7176 38.9342 31.7174 33.7961 42.3697 30.1994C50.1485 27.5779 58.3483 25.8373 66.5586 25.3444Z"
                        fill="#365C56"
                      />
                      <path
                        d="M66.558 25.343L58.9688 37.8002L70.5895 51.9982L76.4315 25.3744C73.1579 25.1437 69.8632 25.1437 66.558 25.343Z"
                        fill="#F1675E"
                      />
                      <path
                        d="M58.9799 37.7912L66.5692 25.334C58.3589 25.8268 50.1591 27.578 42.3698 30.1994C31.7174 33.7961 21.7177 38.9342 12.0548 44.7015L0.644531 51.9892L58.9799 37.7912Z"
                        fill="#D3443A"
                      />
                    </svg>
                  </div>
                  <div className="home-heading">
                    <h5>{t("hello.Hello")}👋</h5>
                  </div>
                  <div className="home-para">
                    <h5>{t("hello.howhelp")}</h5>
                  </div>

                  <div className="home-icons-box">
                    <div className="image-div">
                      <img src={image1}></img>
                      <img src={image2}></img>
                      <img src={image3}></img>
                    </div>

                    <div className="total-user">
                      <span className="redish">10k+</span>
                    </div>
                  </div>
                  <div className="hom-background-img">
                    <img src={Background}></img>
                  </div>
                </div>
              ),
            },
            {
              tabName: "Tab2",
              buttonContent: <div> <i className="fa-solid fa-user-headset"></i></div>,
              content: (
                <div className="contact-tab-wrapper">
                  <ContactPage />
                  {/* <ContactForm /> */}
                </div>
              ),
            },
            {
              tabName: "Tab3",
              buttonContent: <div><i class="fa-solid fa-comment"></i> </div>,
              content: (
                <div className="faq-container">
                  <div className="faq-heading-main">
                    <h5>{t("Tabbutton.FAQs")} </h5>
                  </div>
                  <Faqs items={faqItems} />
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Chatbtn;
